import { sendDataLayerEvent } from "./dataLayer";

// Listen for authorization success.
document.addEventListener(
  "AppleIDSignInOnSuccess",
  (
    event: Event & {
      detail: {
        authorization: {
          code: unknown;
          state: unknown;
          id_token: unknown;
        };
      };
    }
  ) => {
    // Handle successful response.
    const authInfo = event.detail.authorization;
    window.location.href = `/connected_account.php?code=${authInfo.code}&state=${authInfo.state}&id_token=${authInfo.id_token}`;
  }
);

document.querySelector("body")?.addEventListener("click", (event) => {
  const target = event.target as HTMLElement | null;
  const button = target?.closest("button");
  const registerForm = target?.closest("form");
  if (
    registerForm?.classList.contains("register_form_apple") &&
    button?.id === "js-signUpButton" &&
    registerForm.querySelector<HTMLInputElement>('input[name="username"]')
      ?.value &&
    registerForm.querySelector<HTMLInputElement>('input[name="accept"]')
      ?.checked
  ) {
    sendDataLayerEvent("AppleLoginCompleted");
  }
});
